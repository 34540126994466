<template>
  <div class="blocked">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BlockedLayout",
};
</script>

<style lang="scss">
.blocked {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  /* height: 100vh; */

  header,
  footer {
    flex-grow: 0;
  }

  main {
    flex-grow: 1;
  }
}

.widget {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column wrap;
  // .widget__wrapper
  &__wrapper {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    @media (max-width: 650px) {
      padding: 0 12px;
    }
  }
  // .widget__header
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 8px;
    @media screen and (max-width: 576px) {
      padding-top: 25px;
    }
  }
  // .widget__footer
  &__footer {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
  // .widget__title
  &__title {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
  // .widget__table
  &__table {
    width: 100%;
    border: none;
    @media (max-width: 700px) {
      min-width: 280px;
    }
    th {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding: 9px 5px 11px 14px;
      text-align: left;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #ffffff80;
      @media (max-width: 620px) {
        padding-left: 10px;
      }
    }
    td {
      padding: 0 5px 0 14px;
      font-size: 13px;
      line-height: 37px;
      letter-spacing: 0.02em;
      white-space: nowrap;
      @media (max-width: 620px) {
        padding-left: 10px;
      }
    }
  }
}
.cabinet .container {
  @media (max-width: 480px) {
    padding: 0 5px;
  }
}
.services-container {
  margin-top: 27px;
  border: 1px solid rgba(110, 232, 252, 0.5);
  box-shadow: 0px 0px 18px rgba(110, 232, 252, 0.2);
  border-radius: 18px;
  position: relative;
  &:first-child {
    margin-top: 18px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(110, 232, 252, 0.7);
    filter: blur(3.5px);
    border-radius: 18px;
    z-index: -1;
  }
  // .services-container__title
  &__title {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    position: relative;
  }
  // .services-container__title-wrapper
  &__title-wrapper {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #061d21;
    display: inline-flex;
    padding: 0 17px;
    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      background: #3c8490;
      top: calc(50% - 4px);
      border-radius: 4px;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  .title {
    width: clamp(140px, 15vw, 160px);
    height: 28px;
    font-family: "Futura New";
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.06em;
    line-height: 2;
    //background: #061d21;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 159px;
    background: linear-gradient(229.64deg, #1d6c7a 0.27%, #082025 119.62%)
        padding-box,
      linear-gradient(
          225deg,
          #a37a1e 0%,
          #d3a84c 18.23%,
          #ffec95 33.85%,
          #e6be69 50%,
          #ffd87b 64.06%,
          #b58f3e 78.65%,
          #956e13 99.48%
        )
        border-box;
    border: 1px solid transparent;
    border-radius: 9px;
    cursor: pointer;

    @media (max-width: 568px) {
      padding: 0 16px;
      line-height: 21px;
      white-space: nowrap;
      min-width: 0;
    }
  }
  // .services-container__title-btn
  &__title-btn {
    &.btn {
      margin-left: 9px;
      width: 28px;
      height: 28px;
      padding: 0;
    }
  }
}
.business-invest {
  // .business-invest__balances
  &__balances {
    display: flex;
    padding: 23px 11px 16px;
    border-bottom: 1px solid rgba(110, 232, 252, 0.5);
    @media (max-width: 1230px) {
      flex-wrap: wrap;
      padding-bottom: 12px;
    }
    @media (max-width: 480px) {
      padding: 22px 0 9px;
    }
  }
  // .business-invest__balance-card
  &__balance-card {
    flex: 1 1 33.333333%;
    margin: 0 7px;
    background: rgba(21, 22, 22, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    @media (max-width: 1230px) {
      flex: 1 1 100%;
      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
  }
  // .business-invest__products-info
  &__products-info {
    border-bottom: 1px solid rgba(110, 232, 252, 0.5);
    padding: 8px 11px 16px;
    @media (max-width: 480px) {
      padding: 10px 3px;
    }
  }
  // .business-invest__history
  &__history {
    padding: 10px 18px 4px;
    @media (max-width: 480px) {
      padding: 5px 0;
    }
  }
}
</style>
