import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VueCountryDropdown from "vue-country-dropdown";

Vue.use(VueCountryDropdown);

Vue.component("VuePhoneNumberInput", VuePhoneNumberInput);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
