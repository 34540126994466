<template>
  <div id="app">
    <component :is="layout"> </component>
  </div>
</template>

<script>
import BlockedLayout from "@/layouts/BlockedLayout.vue";

export default {
  components: {
    BlockedLayout,
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || "empty"}-layout`;
    },
  },
};
</script>

<style lang="scss">
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
ol,
ul,
li {
  list-style: none;
}
a {
  color: #fff;
  text-decoration: none;
}
img {
  max-width: 100%;
}
body {
  font-family: "Futura New", sans-serif;
  height: 100%;
  min-height: 100vh;
  position: relative;
  background: #061d21;
  color: #fff;
  letter-spacing: 0.04em;
}
select {
  outline: none;
  width: 100%;
  height: 37px;
  border: 1px solid rgba(110, 232, 252, 0.5);
  box-shadow: 0px 0px 18px rgba(110, 232, 252, 0.2);
  border-radius: 11px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.07em;
  padding-left: 18px;
  appearance: none;
}
.container {
  width: 100%;
  max-width: calc(1200px + 2rem);
  margin: 0 auto;
  padding: 0 1rem;
}
input {
  font-family: "Futura New", sans-serif;
}
button {
  font-family: "Futura New", sans-serif;
  cursor: pointer;
}
.hash {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 45px;
}
.btn {
  padding: 0;
  background: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  svg {
    fill: currentColor;
  }
}
.close-dropdown {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.btn-outline {
  width: 100%;
  height: 28px;
  background: linear-gradient(#061d21, #061d21) padding-box,
    linear-gradient(
        225deg,
        #a37a1e 0%,
        #d3a84c 18.23%,
        #ffec95 33.85%,
        #e6be69 50%,
        #ffd87b 64.06%,
        #b58f3e 78.65%,
        #956e13 99.48%
      )
      border-box;
  border: 1px solid transparent;
  border-radius: 9px;
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  transition: 0.3s ease-in-out;
  &:hover {
    background: linear-gradient(
        225deg,
        #a37a1e 0%,
        #d3a84c 18.23%,
        #ffec95 33.85%,
        #e6be69 50%,
        #ffd87b 64.06%,
        #b58f3e 78.65%,
        #956e13 99.48%
      )
      border-box;
  }
}
.btn-primary {
  width: 100%;
  height: 28px;
  background: linear-gradient(229.64deg, #1d6c7a 0.27%, #082025 119.62%)
      padding-box,
    linear-gradient(
        225deg,
        #a37a1e 0%,
        #d3a84c 18.23%,
        #ffec95 33.85%,
        #e6be69 50%,
        #ffd87b 64.06%,
        #b58f3e 78.65%,
        #956e13 99.48%
      )
      border-box;
  border: 1px solid transparent;
  border-radius: 9px;
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  transition: 0.3s ease-in-out;
  &:hover {
    color: #a37a1e;
  }
}
.scroll {
  scrollbar-color: #fff #666; /* «цвет ползунка» | «цвет полосы скроллбара» */
  scrollbar-width: thin; /* толщина: auto | thin | none */
  &::-webkit-scrollbar {
    width: 2px; /* ширина для вертикального скролла */
    height: 8px; /* высота для горизонтального скролла */
    background-color: #666;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
}
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  outline: 0;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
  mix-blend-mode: normal;
  z-index: 10000;

  // .modal__backdrop
  &__backdrop {
    background: rgba(21, 22, 22, 0.4);
    backdrop-filter: blur(1.5px);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  // .modal__dialog
  &__dialog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    max-width: 429px;
    min-height: 100vh;
    padding: 16px;
    width: 100%;
    opacity: 1;
    position: relative;
    /* @media (max-width: 480px) {
      max-width: calc(100% - 32px);
    } */
  }
  // .modal__content
  &__content {
    z-index: 2;
    position: relative;
    padding: 20px 24px;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(21, 22, 22, 0.6);
      border: 1px solid rgba(110, 232, 252, 0.5);
      box-shadow: 0px 0px 18px rgba(110, 232, 252, 0.2);
      backdrop-filter: blur(11.5px);
      border-radius: 8px;
      z-index: -1;
    }
    @media (max-width: 568px) {
      padding: 20px 15px;
    }
  }
  // .modal__close
  &__close {
    position: absolute;
    right: -16px;
    top: -16px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(227.55deg, #1d6c7a -25.77%, #082025 89.9%)
        padding-box,
      linear-gradient(
          225deg,
          #a37a1e 0%,
          #d3a84c 18.23%,
          #ffec95 33.85%,
          #e6be69 50%,
          #ffd87b 64.06%,
          #b58f3e 78.65%,
          #956e13 99.48%
        )
        border-box;
    border: 1px solid transparent;
    border-radius: 9px;
    cursor: pointer;
  }
  //.modal__scroll
  &__scroll {
    max-height: calc(100vh - 125px);
    overflow-y: auto;
    padding-right: 10px;
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}
</style>
